const state = {
  isOpen: false
}

const mutations = {
  setOpen (state, data) {
    state.isOpen = data
  }
}

const actions = {

}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
