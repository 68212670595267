const state = {
  isAddMajorDialog: '',
  isUpdateMajorDialog: ''
}

const mutations = {
  setAddMajorDialog (state, data) {
    state.isAddMajorDialog = data
  },
  updateMajorDialog (state, data) {
    state.isUpdateMajorDialog = data
  }
}

const actions = {

}

const getters = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
