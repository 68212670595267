<template>
  <div class="base-pagination">
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
  </div>
</template>

<script>
/**
 * basePagination 分页组件
 * @description 用于列表数据分页
 * @property {Number} total 总条目数
 * @property {Number} pageSize 每页显示条目个数
 * @property {Number} currentPage 当前页数
 * @property {Array} pageSizes 每页显示个数选择器的选项设置
 */
export default {
  props: {
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    },
    currentPage: {
      type: Number,
      default: 1
    },
    pageSizes: {
      type: Array,
      default: () => {
        return [10, 20, 30, 40, 50, 100]
      }
    }
  },
  methods: {
    // 改变显示条目个数
    handleSizeChange (size) {
      this.$emit('handleSizeChange', size)
    },
    // 改变页数
    handleCurrentChange (page) {
      this.$emit('handleCurrentChange', page)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-pagination {
  padding: 20px 0;
  text-align: center;
}
</style>
