<template>
  <div class="header-container">
    <div class="left-box">
      <div class="icon-top">
        <i class="el-icon-s-fold" @click="toggleCollapse"></i>
      </div>
      <el-breadcrumb replace separator="/">
        <el-breadcrumb-item
          @click.native="removeRouteHandle"
          :to="{ path: '/home' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item
          v-for="(item, index) in activeRouter"
          :key="index"
          :to="{ path: item.path }"
        >
          {{ item.name }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="right-box">
      <el-dropdown @command="logoutHandle">
        <el-avatar
          shape="square"
          src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-58581ecd-debd-44e8-a39d-e91f5b5e0083/82f965e6-254e-467a-b2f8-fa2c30018d6a.png"
        >
        </el-avatar>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState('header', {
      isOpen: state => state.isOpen
    }),
    ...mapState('activeRouter', {
      activeRouter: state => state.activeRouter
    })
  },
  methods: {
    /* 展开与隐藏菜单 */
    ...mapMutations('header', ['setOpen']),
    ...mapMutations('activeRouter', ['setActiveRouter']),
    ...mapActions('login', ['logout']),
    toggleCollapse () {
      this.setOpen(!this.isOpen)
    },
    /* 退出登录 */
    logoutHandle (command) {
      if (command === 'logout') {
        this.logout()
        this.$router.push('/login')
      }
    },
    /* 清空当前路由信息 */
    removeRouteHandle () {
      this.setActiveRouter([])
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;
  border-bottom: 1px solid #ccc;
  .left-box {
    display: flex;
    flex: 1;
    .icon-top {
      padding-left: 20px;
      display: flex;
      align-items: center;
      i {
        cursor: pointer;
        font-size: 24px;
        &:hover {
          color: #409eff;
        }
      }
    }
    .el-breadcrumb {
      height: 56px;
      line-height: 56px;
      padding: 0 20px;
    }
  }
  .right-box {
    display: flex;
    align-items: center;
    margin-right: 20px;
    .el-avatar {
      cursor: pointer;
    }
  }
}
</style>
