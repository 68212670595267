import request from '@/utils/service/request'
/** ********************************用户*****************************/
/* 登录 */
const login = (params) => {
  return request.post('/user/login', params)
}

/* 获取用户个人信息 */
const getUserInfo = (params) => {
  return request.post('/user/getRoleInfo', params)
}

/* 获取用户列表 */
const getUser = (params) => {
  return request.post('/user/page', params)
}

/* 删除用户 */
const deleteUser = (params) => {
  return request.post('/user/del/' + params)
}

/* 添加用户 */
const addUser = (params) => {
  return request.post('/user/add', params)
}

/* 更新用户 */
const editUser = (params) => {
  return request.post('/user/edit', params)
}

/* 添加用户 */
const resetPwd = (id) => {
  return request.post('/user/resetPwd/' + id)
}

/* 通过用户id获取用户信息 */
const getUserInfoById = (params) => {
  return request.post('/user/get/' + params)
}

/* 保存用户推广员关联 */
const saveUserPromoter = (params) => {
  return request.post('/user/saveUserPromoter', params)
}

/* 更新查看全部学生标记 */
const updateHighest = (params) => {
  return request.post('/user/updateHighest', params)
}

/* 获取角色列表 */
const getRoleList = (params) => {
  return request.post('/role/list', params)
}

/* 根据用户id获取当前用户角色 */
const getRoleByUserId = (params) => {
  return request.post('/userRole/getRoleInfo/' + params)
}

/* 分配用户角色 */
const setUserRole = (params) => {
  return request.post('/userRole/saveOrEdit', params)
}

/* 新增角色 */
const addRole = (params) => {
  return request.post('/role/add', params)
}

/* 删除角色 */
const deleteRole = (params) => {
  return request.post('/role/del/' + params)
}

/* 更新角色 */
const editRole = (params) => {
  return request.post('/role/edit', params)
}
/* 根据角色ID获取角色信息 */
const getRoleById = (params) => {
  return request.post('/role/getRole/' + params)
}

/* 分配角色菜单 */
const setRoleMenu = (params) => {
  return request.post('/roleMenu/saveOrEdit', params)
}
/* 获取用户权限表示 */
const listPereByUserId = (params) => {
  return request.get('/menu/listPereByUserId/' + params)
}

/* 获取菜单树 */
const listTree = (params) => {
  return request.get('/menu/listTree')
}

/* 根据id获取菜单信息 */
const getMenuById = (params) => {
  return request.get('/menu/get/' + params)
}
/* 查询全部菜单 */
const listAllMenu = (params) => {
  return request.get('/menu/listAllMenu')
}
/* 保存菜单 */
const saveMenu = (params) => {
  return request.post('/menu/save', params)
}
// 根据角色id查询菜单id
const listMenuIdsByRoleId = (params) => {
  return request.get('/menu/listMenuIdsByRoleId/' + params)
}
// 根据用户查询菜单
const listMenuByRole = () => {
  return request.get('/menu/listUserTree')
}
/* 删除菜单 */
const delMenu = (params) => {
  return request.delete('/menu/delete/' + params)
}

export {
  login,
  getUserInfo,
  getUser,
  deleteUser,
  getRoleList,
  getRoleByUserId,
  setUserRole,
  addUser,
  editUser,
  getUserInfoById,
  saveUserPromoter,
  updateHighest,
  addRole,
  deleteRole,
  resetPwd,
  editRole,
  getRoleById,
  setRoleMenu,
  listPereByUserId,
  listTree,
  getMenuById,
  listAllMenu,
  saveMenu,
  listMenuIdsByRoleId,
  listMenuByRole,
  delMenu
}
