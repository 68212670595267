import baseDialog from './components/baseDialog.vue'
import basePagination from './components/basePagination.vue'
import baseUploadFile from './components/baseUploadFile.vue'

// 全局注册组件
export default {
  install: function (Vue) {
    Vue.component('base-dialog', baseDialog)
    Vue.component('base-pagination', basePagination)
    Vue.component('base-upload-file', baseUploadFile)
  }
}
